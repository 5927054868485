export default {
    ole: 'ole en',
    ole_no: 'ole no',

    levels: 'Levels',
    levels_no: 'Nivåer',

    systems: 'Systems',
    systems_no: 'Systemer'
};
