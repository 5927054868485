<template>
  <transition
    name="modal"
    @after-leave="afterLeave">
    <div
      v-if="modalSession"
      class="ModalContainer">
      <div
        class="mask"
        @click="modalClose('cancel')" />
      <component
        :is="modalSession.component"
        class="componentInner"
        v-bind="modalSession.props"
        @signal="modalClose" />
    </div>
  </transition>
</template>

<script>
import { ModalHostMixin } from '@/plugins/modal-helper';
import StandardPopupBox from '@/components/StandardPopupBox';

export default {
    components: { StandardPopupBox },
    mixins: [ModalHostMixin],
    methods: {
        afterLeave() {}
    }
};
</script>

<style lang="scss" scoped>
$transitionDuraction: 0.3s;

.ModalContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: opacity $transitionDuraction ease;

    &.modal-enter,
    &.modal-leave-active {
        opacity: 0;
    }

    &.modal-enter .component,
    &.modal-leave-active .component {
        transform: translate3d(0, 100px, 0);
    }
}

.mask {
    background-color: rgba(black, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.componentInner {
    position: relative;
    height: 100%;
    width: 100%;
}

.component {
    position: relative;
    transition: transform $transitionDuraction ease;
    margin: auto;
}
</style>
