<template>
  <div
    class="AppViewport"
    :style="{ background }">
    <div
      class="viewport"
      :style="{ background: viewportBackground }">
      <transition
        :name="transitionName"
        :mode="transitionMode">
        <slot />
      </transition>
      <ModalContainer
        class="modalHost"
        name="memogenius-game-modal" />
      <DropdownHost
        class="dropdownHost"
        name="memogenius-game-dropdown" />
    </div>
  </div>
</template>

<script>
import ModalContainer from './ModalContainer';
import DropdownHost from '@/components/DropdownHost';

export default {
    components: { ModalContainer, DropdownHost },
    props: {
        background: {
            type: String,
            default: 'black'
        },
        viewportBackground: {
            type: String,
            default: 'white'
        },
        transitionName: {
            type: String,
            default: 'fade'
        }
    },
    computed: {
        transitionMode() {
            return (
                {
                    banner_to_banner: 'out-in',
                    plain: 'out-in'
                }[this.transitionName] || ''
            );
        }
    }
};
</script>

<style scoped lang="scss">
$viewPortWidth: 375;
.AppViewport {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $appFontSize;
}
.viewport {
    position: relative;
    width: #{$viewPortWidth}px;
    max-width: #{$viewPortWidth}px;
    height: 100%;
    max-height: 812px;
    color: white;
    overflow: scroll;
}
@media only screen and (max-width: #{$viewPortWidth}px) {
    .AppViewport {
        font-size: #{$appFontSize / 1px * 100 / $viewPortWidth}vw;
    }
}

.modalHost,
.dropdownHost {
    z-index: 1;
}

.view {
    height: 100%;

    $transitionDuration: 1s;
    &.none_to_none-enter-active,
    &.none_to_none-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    &.none_to_none-enter {
        opacity: 0;
        transform: translate3d(0, 4em, 0);
    }
    &.none_to_none-leave-to {
        opacity: 0;
        transform: translate3d(0, -4em, 0);
    }

    $transitionDuration: 1s;
    &.full_to_banner-enter-active,
    &.full_to_banner-leave-active {
        transition: transform $transitionDuration;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    &.full_to_banner-enter {
        transform: translate3d(0, 100%, 0) translateY(-12em);
    }
    &.full_to_banner-leave-to {
        transform: translate3d(0, -100%, 0) translateY(12em);
    }

    $transitionDuration: 1s;
    &.banner_to_full-enter-active,
    &.banner_to_full-leave-active {
        transition: transform $transitionDuration;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    &.banner_to_full-enter {
        transform: translate3d(0, -100%, 0) translateY(12em);
    }
    &.banner_to_full-leave-to {
        transform: translate3d(0, 100%, 0) translateY(-12em);
    }

    $transitionDuration: 1s;
    &.banner_to_none-enter-active,
    &.banner_to_none-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    &.banner_to_none-enter {
        // opacity: 0;
        transform: translate3d(0, 12em, 0);
    }
    &.banner_to_none-leave-to {
        // opacity: 0;
        transform: translate3d(0, -12em, 0);
    }

    $transitionDuration: 1s;
    &.none_to_banner-enter-active,
    &.none_to_banner-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    &.none_to_banner-enter {
        opacity: 0;
        transform: translate3d(0, -12em, 0);
    }
    &.none_to_banner-leave-to {
        opacity: 0;
        transform: translate3d(0, 12em, 0);
    }

    $transitionDuration: 0.5s;
    &.banner_to_banner-enter-active,
    &.banner_to_banner-leave-active {
        transition: opacity $transitionDuration, transform $transitionDuration;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    &.banner_to_banner-enter {
        opacity: 0;
        transform: translate3d(0, -12em, 0);
    }
    &.banner_to_banner-leave-to {
        opacity: 0;
        transform: translate3d(0, -12em, 0);
    }

    $transitionDuration: 0.3s;
    &.plain-enter-active,
    &.plain-leave-active {
        transition: opacity $transitionDuration linear, transform $transitionDuration linear;
        // position: absolute;
        // top: 0;
        // right: 0;
        // bottom: 0;
        // left: 0;
    }
    &.plain-enter {
        opacity: 0;
        transform: translate3d(0, 1em, 0);
    }
    &.plain-leave-to {
        opacity: 0;
        transform: translate3d(0, 1em, 0);
    }
}
</style>
